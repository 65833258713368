import React, { useState } from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";
import { Tooltip } from "../../Tooltip";
import { LanguageSelector } from "../../LanguageSelector";
import { useTranslation } from "react-i18next";

const FullScreenHeader = ({ textWhite }) => {
	const [stickyHeader, setStickyHeader] = useState(false);
	const [isNavModalClose, setIsNavModalClose] = useState(true);
	const { t } = useTranslation();

	const checkScrollTop = () => {
		let header = document.getElementsByClassName("primary-menu");

		if (header) {
			if (
				document.body.scrollTop > 1 ||
				document.documentElement.scrollTop > 1
			) {
				setStickyHeader(true);
			} else {
				setStickyHeader(false);
			}
		}
	};

	if (typeof window !== "undefined") {
		window.addEventListener("scroll", checkScrollTop);
	}

	return (
		<header id="header" className="sticky-top">
			{/* Navbar */}
			<nav
				className={
					"primary-menu navbar navbar-expand-none navbar-overlay  bg-transparent border-bottom-0 text-5 fw-600 " +
					(stickyHeader ? "sticky-on-top " : " ") +
					(textWhite
						? "navbar-overlay navbar-text-light"
						: "navbar-overlay-dark")
				}
			>
				<div className="container">
					{/* Logo */}
					<Link
						className="logo"
						title="Callum"
						smooth="easeInOutQuint"
						duration={scrollDuration}
						style={{ cursor: "pointer" }}
						to="home"
						onClick={(e) => {
							e.preventDefault();
							setIsNavModalClose(true);
						}}
					>
						<img
							src={textWhite ? "images/logo-light.png" : "images/logo.png"}
							alt="Callum"
						/>
					</Link>
					{/* Logo End */}
					<div
						className={"text-2 ms-auto me-2 " + (textWhite ? "text-white" : "")}
					>
						<LanguageSelector />
					</div>
					<div
						className={"number-phone mx-2 " + (textWhite ? "text-white" : "")}
					>
						<span className="text-6 me-2 text-dark">
							<a href="https://wa.me/666956636">
								<i className="text-dark fab fa-whatsapp" />
							</a>
						</span>
					</div>
					<button
						className={
							"navbar-toggler collapsed mx-2 " + (isNavModalClose ? "" : "show")
						}
						type="button"
						onClick={() => setIsNavModalClose(!isNavModalClose)}
					>
						<span />
						<span />
						<span />
					</button>
					<div
						id="header-nav"
						className={
							"collapse navbar-collapse " + (isNavModalClose ? "" : "show")
						}
					>
						<div className="d-flex flex-column h-100 align-items-center justify-content-center">
							<ul className="navbar-nav">
								<li className="nav-item">
									<Link
										className="nav-link "
										smooth="easeInOutQuint"
										duration={scrollDuration}
										style={{ cursor: "pointer" }}
										activeClass="active"
										spy
										to="home"
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t("home")}
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link "
										smooth="easeInOutQuint"
										duration={scrollDuration}
										style={{ cursor: "pointer" }}
										activeClass="active"
										spy
										to="about"
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t("about-me")}
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link "
										smooth="easeInOutQuint"
										duration={scrollDuration}
										style={{ cursor: "pointer" }}
										activeClass="active"
										spy
										to="services"
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t("what-i-do")}
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link "
										smooth="easeInOutQuint"
										duration={scrollDuration}
										style={{ cursor: "pointer" }}
										activeClass="active"
										spy
										to="resume"
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t("resume")}
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link "
										smooth="easeInOutQuint"
										duration={scrollDuration}
										style={{ cursor: "pointer" }}
										activeClass="active"
										spy
										to="portfolio"
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t("portfolio")}
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link "
										smooth="easeInOutQuint"
										duration={scrollDuration}
										style={{ cursor: "pointer" }}
										activeClass="active"
										spy
										to="faq"
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t("faq")}
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link "
										smooth="easeInOutQuint"
										duration={scrollDuration}
										style={{ cursor: "pointer" }}
										activeClass="active"
										spy
										to="testimonial"
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t("client-opinion")}
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link "
										smooth="easeInOutQuint"
										duration={scrollDuration}
										style={{ cursor: "pointer" }}
										activeClass="active"
										spy
										to="contact"
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t("contact-me")}
									</Link>
								</li>
								<li className="nav-item">
									<li className="nav-item">
										<Link
											className="nav-item d-sm-block d-md-block d-lg-none"
											smooth="easeInOutQuint"
											duration={scrollDuration}
											style={{ cursor: "pointer" }}
											activeClass="active"
											spy
											to="contact"
											onClick={(e) => {
												e.preventDefault();
												setIsNavModalClose(true);
											}}
										>
											<span className="text-4 me-2">
												<i className="fas fa-phone" />
											</span>
											(+34) 666956636
										</Link>
									</li>
								</li>
							</ul>
							<ul className="social-icons social-icons-light social-icons-lg social-icons-light d-inline-flex mt-4">
								<li className="social-icons-instagram">
									<Tooltip text="Instagram" placement="top">
										<a
											href="https://www.instagram.com/afa_creations"
											target="_blank"
											rel="noopener noreferrer"
										>
											<i className="fab fa-instagram" />
										</a>
									</Tooltip>
								</li>
								<li className="social-icons-linkedin">
									<Tooltip text="Linkedin" placement="top">
										<a
											href="https://www.linkedin.com/in/alex-fernandez-arroyo/"
											target="_blank"
											rel="noopener noreferrer"
										>
											<i className="fab fa-linkedin-in" />
										</a>
									</Tooltip>
								</li>
								<li className="social-icons-github">
									<Tooltip text="Github" placement="top">
										<a
											href="https://github.com/fernandezAlex"
											target="_blank"
											rel="noopener noreferrer"
										>
											<i className="fab fa-github" />
										</a>
									</Tooltip>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
			{/* Navbar End */}
		</header>
	);
};

export default FullScreenHeader;
